<template>
  <div>
    <transition
      name="slide"
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <div v-if="isVisible" @click.stop="close()" class="slideMenu">
        <div class="slideMenu-content">
          <img
            src="@/assets/img/m/nav/close.png"
            @click.stop="close()"
            class="close-icon"
          />
          <div style="height: 80px" />
          <div
            v-for="(item, index) in navList"
            :key="index"
            class="menu-item"
            :class="activeSection == item.selection ? 'text-[#005fe6]' : ''"
            @click.stop="scrollTo(item.selection)"
          >
            <div
              :class="
                activeSection == item.selection
                  ? 'menu-actived'
                  : 'menu-default'
              "
            />
            {{ item.name }}
          </div>
          <div @click.stop="scrollTo('selection6')" class="menu-link-we">
            联系我们
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { scrollToSelection } from '@/utils/selection';
export default {
  props: {
    navList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isVisible: false,
      activeSection: this.navList[0].selection,
    };
  },
  mounted() {
    console.log('mounted', this.activeSection);
  },
  methods: {
    close() {
      this.$emit('closeMenu');
    },
    beforeEnter(el) {
      el.style.transform = 'translateX(100%)';
    },
    enter(el, done) {
      el.offsetHeight; // trigger reflow
      el.style.transition = 'transform 0.3s ease';
      el.style.transform = 'translateX(0)';
      done();
    },
    leave(el, done) {
      el.style.transition = 'transform 0.3s ease';
      el.style.transform = 'translateX(100%)';
      done();
    },
    scrollTo(selection) {
      this.activeSection = selection;
      selection = scrollToSelection(selection);
      this.close();
    },
  },
};
</script>
<style scoped>
@import '@/css/m/slideMenu.css';
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.nav-right-menu-bg {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
